module.exports = {
  background: '#F4F5FA',
  backgroundDark: '#F4F4F4',

  primary: '#00A217',
  primaryLight: '#00C422',
  primaryDark: '#009217',

  secondary: '#F19B34',
  secondaryLight: '#F1AF34',
  secondaryDark: '#D28A3F',
};
